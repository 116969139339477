import React from "react";
import { useTranslation } from "react-i18next";
import { CardHeader, MainCard } from "../sharedComponents/CardComponents";
import styles from "./Community.module.scss";

const CommunityCard = ({ infoText, altText, linkUrl, logo, hackLinebreak }) => {
  if (hackLinebreak) {
    return (
      <div className={styles.communityCard}>
        <a href={linkUrl} target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
          <img src={logo} alt={altText} className={styles.picture}/>
          <div className={styles.infoText}><br/>{infoText}</div>
        </a>
      </div>
    );
  }
  else {
    return (
      <div className={styles.communityCard}>
        <a href={linkUrl} target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
          <img src={logo} alt={altText} className={styles.picture}/>
          <div className={styles.infoText}>{infoText}</div>
        </a>
      </div>
    );
  }

};

const Community = () => {
  const { t } = useTranslation();

  return (
    <MainCard cardStyle="columnCard" id={"communityContainer"}>
      <CardHeader title={t("Pages.FrontPage.Community.Title")} />
      <div className={styles.cards}>
        <CommunityCard
          altText={t("Pages.FrontPage.Community.AltText.Discord")}
          logo="/images/discordLogo.png"
          title="Discord"
          label={t("Pages.FrontPage.Community.InfoText.Discord")}
          infoText={t("Pages.FrontPage.Community.InfoText.Discord")}
          linkUrl="https://discord.com/invite/friendsanddragons"
        />
        <CommunityCard
          altText={t("Pages.FrontPage.Community.AltText.Wiki")}
          logo="/images/wikiLogo.png"
          title="Wiki"
          label={t("Pages.FrontPage.Community.InfoText.Wiki")}
          infoText={t("Pages.FrontPage.Community.InfoText.Wiki")}
          linkUrl="https://friends-and-dragons.fandom.com/wiki/Friends_and_Dragons_Wiki"
        />
        <CommunityCard
          altText={t("Pages.FrontPage.Community.AltText.Treasure")}
          logo="/images/treasureChest.png"
          label={t("Pages.FrontPage.Community.InfoText.Treasure")}
          infoText={t("Pages.FrontPage.Community.InfoText.Treasure")}
          linkUrl=""
          hackLinebreak={true}
        />
      </div>
    </MainCard>
  );
};

export default Community;
