import React from "react";
import { useTranslation } from "react-i18next";
import { combineStyleArray } from "../utils/styleUtils";
import styles from "./Picture.module.scss";

const Picture = ({ altText, onClick, picStyle, source }) => {
  const { t } = useTranslation();
  const styling = combineStyleArray(picStyle, styles);

  return (
    <img src={source} alt={t(altText)} className={styling} onClick={onClick} />
  );
};

export default Picture;
