import React from "react";
import { useTranslation } from "react-i18next";
import { BasicButton } from "../../sharedComponents/Buttons";
import { useDialog } from "../../contextFactory";
import styles from "./Modal.module.scss";

const Modal = () => {
  const { t } = useTranslation();
  const { closeDialog, isOpen, dialogType } = useDialog();

  if (isOpen && dialogType === "modal") {
    return (
      <React.Fragment>
        <div className={styles.background} onClick={closeDialog} />
        <div className={styles.modal} aria-hidden={isOpen ? "false" : "true"}>
          <div className={styles.modalHeader}>
            <h5 className={styles.modalTitle}>{t("Modal.Login.Title")}</h5>
          </div>
          <div className={styles.modalContent}></div>
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <BasicButton
                buttonStyle="warning"
                label={t("Common.Cancel")}
                onClick={closeDialog}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default Modal;
