import DOMPurify from "dompurify";

const sanitize = (dirty, options) => ({
  __html: DOMPurify.sanitize(dirty, { ...options }),
});

export const SanitizeHTML = ({ element, elemStyle, html, options }) => {
  const Tag = element || "div";

  return (
    <Tag
      className={elemStyle}
      dangerouslySetInnerHTML={sanitize(html, options)}
    />
  );
};
