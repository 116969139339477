import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { combineStyleArray } from "../utils/styleUtils";
import { BasicButton } from "./Buttons";
import styles from "./Card.module.scss";
import { useState } from 'react';
import Picture from "../components/Picture";

export const CardHeader = ({ customStyle, subHeader, title }) => {
  const mainStyle = subHeader ? "subHeader" : "mainHeader";
  const styling = combineStyleArray([mainStyle, customStyle], styles);
  return <h1 className={styling}>{title}</h1>;
};

export const CardInfoHeader = ({ customStyle, subHeader, title }) => {
  const mainStyle = subHeader ? "subInfoHeader" : "mainInfoHeader";
  const styling = combineStyleArray([mainStyle, customStyle], styles);
  return <h1 className={styling}>{title}</h1>;
};

export const SectionDivider = () => {
  return <div className={styles.divider} role="separator" />;
};

export const MainCard = ({ children, cardStyle, id }) => {
  const styling = cardStyle
    ? combineStyleArray(cardStyle, styles)
    : styles.columnCard;
  return (
    <Fragment>
      <div className={styling} id={id}>
        {children}
      </div>
      <SectionDivider />
    </Fragment>
  );
};

export const ProductCard = ({
  currency,
  discountPrice,
  name,
  onClick,
  picture,
  price,
  info
}) => {
  const { t } = useTranslation();
  const [infoStatus, setInfoStatus] = useState({
    shouldShow: false
  });
  const showInfo = () => {
    setInfoStatus({
      shouldShow: !infoStatus.shouldShow
    })
    console.log(infoStatus.shouldShow)
  }
  
  return (
    <div
      className={`${styles.productCard} ${
        discountPrice ? styles.discount : ""
      }`}
    >
      <Picture
        source="/images/infoButton.png"
        picStyle="infoButton"
        onClick={showInfo}
      />
      {!infoStatus.shouldShow && name && <CardHeader title={name} customStyle="productHeader" />}
      {!infoStatus.shouldShow && picture}
      {price && (
        <CardHeader
          title={`${price}${currency}`}
          subHeader={true}
          customStyle="priceHeader"
        />
      )}
      {infoStatus.shouldShow && <CardHeader title="You will get all of the following" customStyle="productInfoHeader" />}
      {infoStatus.shouldShow && <CardHeader title={info} customStyle="productInfoBody" />}
      <div className={styles.buyButtonContainer}>
        <BasicButton
          label={t("Pages.FrontPage.Gems.BuyNow")}
          buttonStyle="success"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export const SubordinateCard = ({ children, customStyle, text, title }) => {
  const styling = combineStyleArray([customStyle, "subCard"], styles);
  return (
    <div className={styling}>
      {title && <CardHeader title={title} subHeader={true} />}
      {text && <span>{text}</span>}
      {children && children}
    </div>
  );
};

export const TextContainer = ({ children }) => {
  return <div className={styles.textContainer}>{children}</div>;
};

export const TextElement = ({ children, elem, customStyle }) => {
  const Tag = elem || "span";

  return (
    <Tag className={customStyle ? styles[customStyle] : styles.text}>
      {children}
    </Tag>
  );
};
