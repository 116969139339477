import { createContext, useContext, useEffect, useState } from "react";
import useHttp from "../hooks/useHttp";
import { getUserGameDataConfig } from "../utils/authUtils";
import { gameTokenConfig } from "../utils/shopUtils";
import { useError } from "./ErrorContext";
import { getMetaplayApp } from '../MetaplayApp.ts'
let subscribed = false;
export const UserInfoContext = createContext();

export function useUserInfo() {
  return useContext(UserInfoContext);
}

export const UserInfoContextProvider = ({ children, initialState }) => {
  const { sendRequest } = useHttp();
  const [authStatus, setAuthStatus] = useState({
    isLoggedIn: false,
    isAdmin: false,
  });

  const [userInfo, setUserInfo] = useState({
    gems: undefined,
    level: undefined,
    name: undefined,
    playerId: undefined,
  });
  
  const [productFetchPending, setProductFetchPending] = useState(1);

  const { setError } = useError();
  
  useEffect( () => {
    const fetchUserData = async () => {
      const gameToken = await sendRequest(gameTokenConfig);
      const userGameDataConfig = getUserGameDataConfig(gameToken);
      await sendRequest(userGameDataConfig, setUserInfo);
    };
    
    const MetaplayEventHandler = {
      onAccountChanged: (accountInfo) => {
        const metaplayApp = getMetaplayApp();
        let loggedIn = metaplayApp.isLoggedIn();
        setAuthStatus({ isLoggedIn: loggedIn });
        if (loggedIn) {
          fetchUserData();
        }
      }
    }
    if (!subscribed) {
      getMetaplayApp().subscribeToEvents(MetaplayEventHandler);
      subscribed = true;
    }
  }, [sendRequest]);
  
  useEffect(() => {
    const fetchData = async () => {
      if (getMetaplayApp().isLoggedIn()) {
        setAuthStatus({ isLoggedIn: true });
        const gameToken = await sendRequest(gameTokenConfig);
        const userGameDataConfig = getUserGameDataConfig(gameToken);
        await sendRequest(userGameDataConfig, setUserInfo);
      }
    };
    fetchData();
  }, [sendRequest, setError]);

  useEffect(() => {
    if (initialState) {
      setAuthStatus(initialState.authStatus);
      setUserInfo(initialState.userInfo);
    }
  }, [initialState]);

  const value = {
    authStatus,
    setAuthStatus,
    userInfo,
    setUserInfo,
    productFetchPending,
    setProductFetchPending
  };
  
  return (
    <UserInfoContext.Provider value={value}>
      {children}
    </UserInfoContext.Provider>
  );
};
