const newsUrl = process.env.REACT_APP_NEWS_URL;

export const saveNews = (data, submitSuccessHandler, setError) => {
  const apiKey = window.localStorage.getItem("newsKey");
  fetch(newsUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": apiKey,
    },
    body: JSON.stringify(data),
  })
    .then(() => {
      submitSuccessHandler();
    })
    .catch((err) => {
      setError(err);
    });
};

export const getNewsItem = (newsId, news) => {
  const newsItem =
    news && newsId
      ? news.find((item) => {
          return item.id.toString() === newsId.toString();
        })
      : undefined;
  return newsItem;
};

export const convertNews = (news) => {
  const selectNewsProps = (news) => {
    const { _id, body, title } = news;
    return { id: _id, content: body, title };
  };
  const convertedNews = news.map(selectNewsProps);
  return convertedNews;
};

export const deleteNewsItem = (id, deleteHandler, setError) => {
  const apiKey = window.localStorage.getItem("newsKey");
  fetch(newsUrl, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": apiKey,
    },
    body: JSON.stringify({ _id: id }),
  })
    .then(() => {
      deleteHandler();
    })
    .catch((err) => {
      setError(err);
    });
};
