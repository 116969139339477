import { IconButton } from "../sharedComponents/Buttons";
import {
  DiscordIcon,
  FacebookIcon,
  InstagramIcon,
  TwitchIcon,
  TwitterIcon,
  YouTubeIcon,
} from "./Icons";
import styles from "./SocialMediaIcons.module.scss";

const SocialMediaIcons = () => {
  return (
    <div className={styles.iconRow}>
      <IconButton
        buttonStyle="iconButton"
        icon={FacebookIcon}
        linkTo="https://www.facebook.com/friendsanddragons/"
      />
      <IconButton
        buttonStyle="iconButton"
        icon={InstagramIcon}
        linkTo="https://www.instagram.com/friendsanddragons/?hl=fi"
      />
      <IconButton
        buttonStyle="iconButton"
        icon={YouTubeIcon}
        linkTo="https://www.youtube.com/@friendsdragons4120"
      />
      <IconButton
        buttonStyle="iconButton"
        icon={TwitterIcon}
        linkTo="https://twitter.com/friendsndragons"
      />
      <IconButton
        buttonStyle="iconButton"
        icon={DiscordIcon}
        linkTo="https://discord.com/invite/zAaBAKqfNs"
      />
      <IconButton
        buttonStyle="iconButton"
        icon={TwitchIcon}
        linkTo="https://www.twitch.tv/directory/game/Friends%20&%20Dragons/clips?range=7d"
      />
    </div>
  );
};

export default SocialMediaIcons;
