import React from "react";
import { useTranslation } from "react-i18next";
import Picture from "../Picture";
import {
  CardHeader,
  MainCard,
  SubordinateCard,
  TextContainer,
} from "../../sharedComponents/CardComponents";
import { Divider } from "../Icons";

const BuyGems = () => {
  const { t } = useTranslation();

  return (
    <MainCard cardStyle={["rowCard", "centered"]}>
      <Picture
        source="/images/amberwing.png"
        picStyle="hideInSmallerThanDesktop"
      />
      <TextContainer>
        <CardHeader title={t("Pages.FrontPage.Gems.Buy")} />
        <Divider />
        <SubordinateCard
          title={t("Pages.FrontPage.Gems.What.Title")}
          text={t("Pages.FrontPage.Gems.What.Text")}
        />
        <SubordinateCard
          title={t("Pages.FrontPage.Gems.Benefit.Title")}
          text={t("Pages.FrontPage.Gems.Benefit.Text")}
        />
        <SubordinateCard
          title={t("Pages.FrontPage.Gems.HowTo.Title")}
          text={t("Pages.FrontPage.Gems.HowTo.Text")}
        />
      </TextContainer>
    </MainCard>
  );
};

export default BuyGems;
