import React from "react";
import ErrorComponent from "../../components/Error/ErrorComponent";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";
import Spinner from "../../components/Spinner";
import { useLoading } from "../../contextFactory";
import { useError } from "../../contextFactory";
import styles from "./Layout.module.scss";

const Layout = ({ children }) => {
  const { isLoading } = useLoading();
  const { error } = useError();

  return (
    <div className={styles.app}>
      {isLoading && <Spinner />}
      <Header />
      {error && <ErrorComponent id="topError" error={error} />}
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
