import { BrowserRouter } from "react-router-dom";
import Layout from "./hoc/Layout/Layout";
import AppRoutes from "./routes/AppRoutes";
import ErrorBoundary from "./hoc/ErrorBoundary/ErrorBoundary";
import Modal from "./components/DialogComponents/Modal";
import { ContextWrapper } from "./contextFactory";

const App = () => {
  return (
    <ErrorBoundary>
      <ContextWrapper>
        <BrowserRouter>
          <Layout>
            <AppRoutes />
            <Modal />
          </Layout>
        </BrowserRouter>
      </ContextWrapper>
    </ErrorBoundary>
  );
};

export default App;
