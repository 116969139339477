export const checkErrors = (response, data, setError) => {
  if (
    data?.error?.statusCode === 500 &&
    data.error?.details?.includes("targetEntityId must be a valid entity")
  ) {
    setError("Errors.NotLinked");
  } else if (data.statusCode === 401) {
    setError("Errors.NotLoggedIn");
  } else if (response.status === 401) {
    setError("Errors.NotAuthorized");
  } else if (data.statusCode === 404 || response.status === 404) {
    setError("Errors.ResourceNotFound");
  } else if (data.statusCode === 500 || response.status === 500) {
    setError("Errors.Maintenance");
  } else if (!response.ok) {
    setError("Errors.RequestFailed");
  }
};
