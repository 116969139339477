const authApiBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;
const gameServerBaseUrl = process.env.REACT_APP_GAME_SERVER_BASE_URL;
const xsollaApiBaseUrl = process.env.REACT_APP_XSOLLA_API_BASE_URL;
const projectId = process.env.REACT_APP_XSOLLA_PROJECT_ID;

export const gameTokenConfig = {
  url: `${authApiBaseUrl}/selfServe/tokenForGameWebAccess?${new URLSearchParams(
    {
      project: "behemoth",
      env: process.env.REACT_APP_ENVIRONMENT_NAME,
    }
  )}`,
  options: { resposeType: "text", credentials: "include" },
  respType: "text",
};

export const getPaymentTokenConfig = (bearer, itemSku) => {
  return {
    url: `${gameServerBaseUrl}/xsollaAuthToken/${itemSku}`,
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + bearer,
      },
    },
    returnableResource: "token",
  };
};

// @TODO: fix req body params
export const getXsollaTokenConfig = (bearer, itemSku) => {
  return {
    returnableResource: "token",
    url: `${xsollaApiBaseUrl}/${projectId}/payment/item/${itemSku}`,
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearer}`,
      },
      body: JSON.stringify({
        quantity: 1,
        settings: {
          ui: {
            size: "large",
            theme: "63295aab2e47fab76f7708e3",
            version: "desktop",
            desktop: {
              header: {
                is_visible: true,
                visible_logo: true,
                visible_name: true,
                visible_purchase: true,
                type: "normal",
                close_button: true,
              },
            },
            mobile: {
              footer: { is_visible: true },
              header: { close_button: true },
            },
          },
        },
        custom_parameters: {
          sku: `${itemSku}`,
        },
      }),
    },
  };
};

export const xsollaProductsConfig = (bearer) => {
  return {
    url: `${process.env.REACT_APP_GAME_SERVER_BASE_URL}/shopProducts`,
    options: {
      headers: {
        Authorization: "Bearer " + bearer,
      }
    }
  }
};

export const getCurrencySymbol = (currency) => {
  const numberFormat = new Intl.NumberFormat("en", {
    style: "currency",
    currency,
  });
  const result = numberFormat
    .formatToParts(numberFormat)
    .find((x) => x.type === "currency");
  const symbol = result?.value || "$";
  return symbol;
};
