import { createContext, useContext, useEffect, useState } from "react";

const DialogContext = createContext();

export function useDialog() {
  return useContext(DialogContext);
}

export const DialogContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogType, setDialogType] = useState(undefined);
  const [dialogContent, setDialogContent] = useState(undefined);

  const closeDialog = () => {
    setIsOpen(false);
    setDialogType(undefined);
    setDialogContent(undefined);
  };

  useEffect(() => {
    if (!isOpen) {
      setDialogType(undefined);
    }
  }, [isOpen]);

  const value = {
    closeDialog,
    isOpen,
    dialogContent,
    dialogType,
    setIsOpen,
    setDialogContent,
    setDialogType,
  };

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  );
};
