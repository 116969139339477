import React, { Component } from "react";
import ErrorComponent from "../../components/Error/ErrorComponent";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(errorInfo);
    console.log(
      `Error caught in error boundary, error: ${error}, errorInfo: ${errorInfo}`
    );
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent textId="Errors.ErrorBoundary" />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
