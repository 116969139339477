import React from "react";
import { useTranslation } from "react-i18next";
import { BasicButton, LinkButton } from "../sharedComponents/Buttons";
import styles from "./Footer.module.scss";
import Picture from "./Picture";
import SocialMediaIcons from "./SocialMediaIcons";
import {Route, Routes} from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  const openEmail = () => {
    window.open("mailto:support@playsome.fi");
  };

  return (
    <Routes>
      <Route path="/play" element={null} />
      <Route path="/*" element={
        <footer className={styles.footer}>
          <SocialMediaIcons />
          <span className={styles.terms}>
        <LinkButton
          linkStyle="linkButton"
          label={t("Footer.Terms")}
          linkTo="https://www.playsome.fi/terms-of-service"
        />
        <LinkButton
          linkStyle="linkButton"
          label={t("Footer.Privacy")}
          linkTo="https://www.playsome.fi/privacy-policy"
        />
        <BasicButton
          buttonStyle="linkButton"
          label={t("Footer.Contact")}
          onClick={openEmail}
        />
      </span>
          <Picture
            source={"/images/playsomeLogo.PNG"}
            picStyle="footerLogo"
            altText={t("Common.PlaysomeLogo")}
          />
          <span className={styles.rights}>{t("Footer.Rights")}</span>
        </footer>}/>
    </Routes>
  );
};

export default Footer;
