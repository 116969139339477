import { createContext, useContext, useEffect, useState } from "react";

const ErrorContext = createContext();

export function useError() {
  return useContext(ErrorContext);
}

export const ErrorContextProvider = ({ children }) => {
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(undefined);
      }, 15000);
    }
  }, [error]);

  const value = {
    error,
    setError,
  };

  return (
    <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
  );
};
