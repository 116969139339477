import { useCallback } from "react";
import { useError, useLoading } from "../contextFactory";
import { checkErrors } from "../utils/httpUtils";

const useHttp = () => {
  const { setIsLoading } = useLoading();
  const { error, setError } = useError();

  const sendRequest = useCallback(
    async (requestConfig, applyData, useLoading = true) => {
      if (useLoading) {
        setIsLoading(true);
      }


      try {
        const response = await fetch(requestConfig.url, requestConfig.options);

        if (response) setIsLoading(false);

        const data =
          requestConfig.respType === "text"
            ? await response?.text()
            : await response?.json();

        checkErrors(response, data, setError);
        if (error) return;

        const returnable = requestConfig.returnableResource
          ? data[requestConfig.returnableResource]
          : data;

        if (applyData) applyData(returnable);

        return returnable;
      } catch (err) {
        if (typeof err.response === "undefined") {
          return setError("Errors.Maintenance");
        }
        setError(err);
        if (useLoading) {
          setIsLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setError, setIsLoading]
  );

  return {
    sendRequest,
  };
};

export default useHttp;
