import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./i18n";
import {singularSdk, SingularConfig} from "singular-sdk";
import { getMetaplayApp } from './MetaplayApp.ts'

const options = {
    projectName: 'behemoth',
    accountApiUrl: process.env.REACT_APP_AUTH_BASE_URL + "/selfServe",
    gameServerUrl: process.env.REACT_APP_GAME_SERVER_BASE_URL,
    environment: process.env.REACT_APP_ENVIRONMENT_NAME,
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

export const metaplayApp = getMetaplayApp()
metaplayApp.initialize(options)

// Initialize Firebase
// eslint-disable-next-line no-undef
firebase?.initializeApp(firebaseConfig);

setTimeout(() => {
  mapSingularUserId();
}, 5000);

function mapSingularUserId() {
  // Extract firebase user pseudo id from cookies to map to Singular
  try {
    const firebasePseudoId = (document.cookie.match('(^|;)\\s*_ga\\s*=\\s*([^;]+)')?.pop() || '').split('.').slice(-2).join(".");
    if (firebasePseudoId === "") {
      setTimeout(() => {
        mapSingularUserId();
      }, 5000);
    }
    else {
      const singularConfig = new SingularConfig(process.env.REACT_APP_SINGULAR_KEY, process.env.REACT_APP_SINGULAR_SECRET, process.env.REACT_APP_SINGULAR_PRODUCTID).withCustomUserId(firebasePseudoId);
      singularSdk.init(singularConfig);
    }
  }
  catch (error) {
    console.log(error);
    setTimeout(() => {
      mapSingularUserId();
    }, 5000);
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
