import React from "react";
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from "react-device-detect";
import { BasicButton, MobileStoreButtons } from "../sharedComponents/Buttons";
import {
  CardHeader,
  MainCard,
  SubordinateCard,
  TextContainer,
  TextElement,
} from "../sharedComponents/CardComponents";
import { useGame } from "../contextFactory";
import { Divider } from "./Icons";
import Picture from "./Picture";

const Download = () => {
  const { t } = useTranslation();
  const { openGame } = useGame();

  return (
    <MainCard cardStyle={["rowCard", "centered"]}>
      <TextContainer>
        <CardHeader title={t("Pages.FrontPage.Download.Title")} />
        <Divider />
        <TextElement>{t("Pages.FrontPage.Download.InfoText")}</TextElement>
        <SubordinateCard
          customStyle="downloadCard"
          title={t("Pages.FrontPage.Download.PlayGame")}
        >
          <BrowserView>
            <BasicButton
              buttonStyle={["success", "significant"]}
              label={t("Pages.FrontPage.PlayNow.ButtonText")}
              onClick={openGame}
            />
          </BrowserView>
          <MobileView>
            <MobileStoreButtons />
          </MobileView>
        </SubordinateCard>
      </TextContainer>
      <Picture
        source="/images/magicIcon.png"
        picStyle={["magicIcon", "hideInSmallerThanDesktop"]}
      />
    </MainCard>
  );
};

export default Download;
