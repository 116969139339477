import React from "react";
import { useTranslation } from "react-i18next";
import { useUserInfo } from "../contextFactory";
import { CardInfoHeader, MainCard } from "../sharedComponents/CardComponents";

const Welcome = () => {
  const { t } = useTranslation();
  const { authStatus, userInfo } = useUserInfo();
  const unlinkedAccount = authStatus?.isLoggedIn && !userInfo?.name;

  const username = userInfo?.name || t("Pages.FrontPage.Welcome.Placeholder");

  const headerTitle =
    t("Pages.FrontPage.Welcome.Header") +
    " " +
    username +
    t("Common.Exclamation");

  return (
    <MainCard cardStyle="headerCard">
      <CardInfoHeader title={headerTitle} />
      {unlinkedAccount && (
        <CardInfoHeader
          subHeader={true}
          title={t("Pages.FrontPage.Welcome.UnlinkedAccount")}
        />
      )}
    </MainCard>
  );
};

export default Welcome;
