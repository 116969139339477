import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReturnButton } from "../../sharedComponents/Buttons";
import { CardHeader, MainCard } from "../../sharedComponents/CardComponents";

const ErrorComponent = ({ id, error, returnTo, titleId, textId }) => {
  const { t } = useTranslation();
  const errorTitle = titleId ? t(titleId) : t("Errors.General");
  const errorText = error?.message
    ? error.message
    : error
    ? t(error)
    : t(textId);

  useEffect(() => {
    const errorElem = document.getElementById(id);
    if (errorElem) errorElem.scrollIntoView();
  }, [id]);

  return (
    <MainCard id={id}>
      <CardHeader title={errorTitle} customStyle="errorHeader" />
      <CardHeader
        title={errorText}
        subHeader={true}
        customStyle="errorSubHeader"
      />
      {returnTo && <ReturnButton returnTo={returnTo} />}
    </MainCard>
  );
};

export default ErrorComponent;
