import { useDialog } from "../../contextFactory";
import styles from "./Drawer.module.scss";

const Drawer = ({ children }) => {
  const { closeDialog, isOpen, dialogType } = useDialog();

  if (isOpen && dialogType === "drawer") {
    return (
      <div
        aria-hidden={isOpen ? "false" : "true"}
        className={`${styles.drawerContainer} ${isOpen ? styles.open : ""}`}
      >
        <div className={styles.drawer} role="dialog">
          {children}
        </div>
        <div className={styles.backdrop} onClick={closeDialog} />
      </div>
    );
  }
};

export default Drawer;
