import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useError, useNews } from "../contextFactory";
import { SanitizeHTML } from "../utils/sanitizeUtils";
import { deleteNewsItem } from "../utils/newsUtils";
import { BasicButton } from "../sharedComponents/Buttons";
import styles from "./NewsListItem.module.scss";
import { SubordinateCard } from "../sharedComponents/CardComponents";

const NewsListItem = ({ newsItem, inAdminPages }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getNews } = useNews();
  const { setError } = useError();

  const linkTo = inAdminPages
    ? "/adminTool/" + newsItem.id
    : "/news/" + newsItem.id;

  const deleteHandler = () => {
    getNews();
    navigate("/admintool");
  };

  const handleDeleteClick = (event) => {
    deleteNewsItem(event.target.id, deleteHandler, setError);
  };

  return (
    <SubordinateCard customStyle="newsCard">
      <Link className={styles.newsLink} to={linkTo}>
        <SanitizeHTML
          html={newsItem.title}
          element="h1"
          elemStyle={styles.newsTitle}
        />
        <SanitizeHTML html={newsItem.content} element="div" />
      </Link>
      {inAdminPages && (
        <BasicButton
          buttonStyle="warning"
          id={newsItem.id}
          label={t("Common.Delete")}
          onClick={handleDeleteClick}
        />
      )}
    </SubordinateCard>
  );
};

export default NewsListItem;
