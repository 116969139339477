import React, { useEffect } from "react";
import { useError, useGame } from "../contextFactory";
import styles from "./GameFrame.module.scss";
import { BasicButton } from "../sharedComponents/Buttons";
import { MainCard } from "../sharedComponents/CardComponents";

const GameFrame = () => {
  const { setGameStatus, gameStatus } = useGame();
  const { setError } = useError();

  const getFullScreen = async () => {
    const gameScr = document.getElementById("unity-container");
    if (gameScr?.requestFullscreen) {
      gameScr.requestFullscreen();
    } else if (gameScr?.webkitRequestFullscreen) {
      gameScr.webkitRequestFullscreen();
    } else {
      setGameStatus({ isOpen: false });
      setError("Errors.GameFrame.FullScreenError");
    }
  };

  useEffect(() => {
    const gameScr = document.getElementById("gameHeader");
    if (gameScr) {
      gameScr.scrollIntoView();
    }
  }, [gameStatus]);

  const handleFullScreenClick = async () => {
    getFullScreen();
  };
  
  const handleCloseGameClick = () => {
    window.location.reload(false);
    //stopGame();
    //setGameStatus({ isOpen: false });
  };
  
  if (gameStatus.isOpen) {
    return (
      <MainCard cardStyle="gameFrame" id="gameContainer">
        <div className={styles.buttons} id="gameHeader">
          <BasicButton
            buttonStyle="fullscreen"
            onClick={handleFullScreenClick}
          />
          <BasicButton
            buttonStyle="closegame"
            onClick={handleCloseGameClick}
          />
        </div>
        <div id="unity-container" className="unity-desktop">
          <canvas id="unity-canvas"></canvas>
        </div>
        <div id="loading-cover" style={{display: "none"}}>
          <div id="unity-loading-bar">
            <div id="unity-logo"><img src="images/gameLoadingLogo.png" alt="logo"/></div>
            <div id="unity-progress-bar-empty" style={{display: "none"}}>
              <div id="unity-progress-bar-full"></div>
            </div>
            <div className="spinner"></div>
          </div>
        </div>
        <div id="unity-fullscreen-button" style={{display: "none"}}></div>
      </MainCard>
    );
  }
  else {
    /*
    return ( // Need to keep these elements alive but hidden in order to resume game later
        <MainCard cardStyle="gameFrame">
          <div id="unity-container" style={{display: "none"}}>
            <canvas id="unity-canvas"></canvas>
          </div>
          <div id="loading-cover" style={{display: "none"}}>
            <div id="unity-loading-bar">
              <div id="unity-logo"><img src="images/gameLoadingLogo.png" alt="logo"/></div>
              <div id="unity-progress-bar-empty" style={{display: "none"}}>
                <div id="unity-progress-bar-full"></div>
              </div>
              <div className="spinner"></div>
            </div>
          </div>
          <div id="unity-fullscreen-button" style={{display: "none"}}></div>
        </MainCard>
    )
    */
  }
};

export default GameFrame;
