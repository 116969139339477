import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppStoreIcon, GooglePlayIcon } from "../components/Icons";
import { useLoading } from "../contextFactory";

import { combineStyleArray } from "../utils/styleUtils";
import styles from "./Buttons.module.scss";

export const BasicButton = ({
  buttonStyle,
  disabled,
  id,
  label,
  onClick,
  type,
}) => {
  const { isLoading } = useLoading();
  const buttonDisabled = isLoading ? true : disabled ? disabled : false;
  const style = Array.isArray(buttonStyle)
    ? combineStyleArray(buttonStyle, styles)
    : styles[buttonStyle];

  return (
    <button
      className={style}
      disabled={buttonDisabled}
      onClick={onClick}
      type={type || "button"}
      id={id || undefined}
    >
      {label}
    </button>
  );
};

export const IconButton = ({ buttonStyle, icon, linkTo }) => {
  const { isLoading } = useLoading();

  return (
    <a
      href={linkTo}
      className={`${styles[buttonStyle]} ${isLoading ? styles.disabled : ""}`}
      target="_blank"
      rel="noreferrer"
    >
      {icon}
    </a>
  );
};

export const LinkButton = ({
  altText,
  imgSource,
  imgStyle,
  linkStyle,
  label,
  linkTo,
}) => {
  const { t } = useTranslation();
  const { isLoading } = useLoading();

  return (
    <a
      href={linkTo}
      target="_blank"
      rel="noreferrer"
      className={`${styles[linkStyle]} ${isLoading ? styles.disabled : ""}`}
    >
      {label}
      {imgSource && (
        <img src={imgSource} alt={t(altText)} className={styles[imgStyle]} />
      )}
    </a>
  );
};

export const MobileStoreButtons = () => {
  return (
    <span className={styles.storeButtons}>
      <IconButton
        linkTo="https://apps.apple.com/fi/app/friends-dragons-puzzle-rpg/id1522259907?l=fi"
        icon={<AppStoreIcon />}
      />
      <IconButton
        linkTo="https://play.google.com/store/apps/details?id=com.playsome.friendsanddragons"
        icon={<GooglePlayIcon />}
      />
    </span>
  );
};

export const ReturnButton = ({ returnTo }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const returnClickHandler = () => {
    navigate(returnTo);
  };

  return (
    <BasicButton
      buttonStyle="inferior"
      label={t("Common.Return")}
      onClick={returnClickHandler}
    />
  );
};

export const SvgButton = ({ buttonStyle, onClick, svgElem }) => {
  const { isLoading } = useLoading();

  return (
    <button
      className={`${styles[buttonStyle]} ${isLoading ? styles.disabled : ""}`}
      onClick={onClick}
    >
      {svgElem}
    </button>
  );
};
