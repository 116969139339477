import React from "react";
import { useTranslation } from "react-i18next";
import { useUserInfo } from "../contextFactory";
import { SectionDivider } from "../sharedComponents/CardComponents";
import Picture from "./Picture";
import styles from "./UserGameData.module.scss";

const PlayerNameAndLevel = ({ username, level }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.name}>
      {username}
      <br/>
      {t("Header.Level")} {level}
    </div>
  );
};

const PlayerAvatar = ({ username }) => {
  // @Todo: get real img source when possible
  const picSrc = undefined;
  const placeholderPicSrc = "/images/profile.png";
  return (
    <div>
      <Picture
        source={picSrc || placeholderPicSrc}
        picStyle={picSrc ? "playerImg" : "placeholderPlayerImg"}
      />
    </div>
  );
};

const GemCount = ({ gemCount }) => {
  return (
    <div className={styles.gems}>
      <Picture source="/images/gem.png" picStyle="gem" /> {gemCount}
    </div>
  );
};

const UserDataMobileWrapper = ({ children }) => {
  return (
    <>
      <SectionDivider />
      {children}
      <SectionDivider />
    </>
  );
};

const UserGameData = ({ mobileElem }) => {
  const { userInfo } = useUserInfo();

  const username = userInfo?.name;
  const gemCount = userInfo?.gems;
  const level = userInfo?.level;

  const content = (
    <div className={mobileElem ? styles.userDataMobile : styles.userData}>
      <GemCount gemCount={gemCount} />
      <PlayerNameAndLevel username={username} level={level} />
      <PlayerAvatar/>
    </div>
  );

  if (mobileElem) {
    return <UserDataMobileWrapper>{content}</UserDataMobileWrapper>;
  } else {
    return content;
  }
};

export default UserGameData;
