import { createContext, useContext, useState } from "react";
import { useError } from "./ErrorContext";
import { initializeUnityContainer } from '../UnityLoader'
import { stopUnityInstance } from '../UnityLoader'
import { singularSdk } from "singular-sdk";
const GameContext = createContext();
const gameSource = process.env.REACT_APP_GAME_SOURCE_URL;

export function useGame() {
  return useContext(GameContext);
}

export const GameContextProvider = ({ children }) => {
  const [gameStatus, setGameStatus] = useState({ isOpen: false });
  const { setError } = useError();

  const openGame = async () => {
    singularSdk.event("clickPlay");
    setError(undefined);
    setGameStatus({isOpen: true});
    const buildProps = await (await fetch(`props.json`, {cache: "no-store"})).json()
    const buildVersion = await (await fetch(gameSource + "/current.version", {cache: "no-store"})).text();
    
    // Purge old builds from cache (they accumulate a lot of disk waste)
    const dbOpen = indexedDB.open("UnityCache")
    dbOpen.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction('RequestStore', 'readwrite')
      const store = transaction.objectStore('RequestStore')
      const cursorRequest = store.openCursor();

      cursorRequest.onerror = (error) => {
        console.log(error);
      };

      cursorRequest.onsuccess = evt => {
        const cursor = evt.target.result;
        if (cursor) {
          if (!cursor.value.url.includes(buildVersion)) {
            console.log('Deleting old cached build entry');
            store.delete(cursor.value.url);
          }
          cursor.continue();
        }
      };
    };
    await initializeUnityContainer(gameSource + "/" + buildVersion + "/", buildProps)
  };
  
  const stopGame = () => {
    stopUnityInstance();
  }

  const value = {
    gameStatus,
    setGameStatus,
    openGame,
    stopGame
  };

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};
