import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet } from "react-router-dom";
import { convertNews } from "../utils/newsUtils";
import { useError } from "./ErrorContext";

const NewsContext = createContext();

export function useNews() {
  return useContext(NewsContext);
}

export const NewsContextProvider = ({ children, initialState }) => {
  const [news, setNews] = useState([]);
  const newsUrl = process.env.REACT_APP_NEWS_URL;

  const { setError } = useError();

  const getNews = useCallback(() => {
    const cached = window.localStorage.getItem("newsCached");
    if (cached) {
      setNews(JSON.parse(cached));
    }
    
    fetch(newsUrl)
      .then((response) => response.json())
      .then((json) => convertNews(json))
      .then((convertedNews) => {
        setNews(convertedNews);
        window.localStorage.setItem("newsCached", JSON.stringify(convertedNews));
      })
      .catch((error) => {
        setError(error);
      });
  }, [newsUrl, setError]);

  useEffect(() => {
    getNews(setNews);
  }, [getNews]);

  useEffect(() => {
    if (initialState) {
      setNews(initialState);
    }
  }, [initialState]);

  const value = {
    news,
    setNews,
    getNews,
  };

  return <NewsContext.Provider value={value}>{children}</NewsContext.Provider>;
};

export const NewsContextLayout = () => {
  return (
    <NewsContextProvider>
      <Outlet />
    </NewsContextProvider>
  );
};
