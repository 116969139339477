import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNews } from "../../contextFactory";
import NewsListItem from "../../components/NewsListItem";
import { CardHeader, MainCard } from "../../sharedComponents/CardComponents";

const NewsList = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { news } = useNews();
  const newsFound = news && news.length > 0;

  const inAdminPages = location && location.pathname === "/admintool";
  const titleId = inAdminPages
    ? "Pages.Admin.AdminTool.NewsList"
    : "Pages.NewsList.NewsList";

  return (
    <MainCard>
      <CardHeader title={t(titleId)} />
      {newsFound ? (
        news.map((newsItem) => {
          return (
            <NewsListItem
              newsItem={newsItem}
              key={newsItem.id}
              inAdminPages={inAdminPages}
            />
          );
        })
      ) : (
        <div>{t("Pages.NewsList.Empty")}</div>
      )}
    </MainCard>
  );
};

export default NewsList;
