import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNews } from "../../contextFactory";
import { getNewsItem } from "../../utils/newsUtils";
import { SanitizeHTML } from "../../utils/sanitizeUtils";
import { ReturnButton } from "../../sharedComponents/Buttons";
import {
  CardHeader,
  MainCard,
  SubordinateCard,
} from "../../sharedComponents/CardComponents";

const NewsItem = () => {
  const [newsItem, setNewsItem] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { news } = useNews();

  useEffect(() => {
    const item = getNewsItem(id, news);
    if (id && item) {
      setNewsItem(item);
    } else if (id && !item) {
      navigate("/news/notfound");
    }
  }, [id, navigate, news]);

  if (newsItem) {
    return (
      <MainCard>
        <SubordinateCard customStyle="newsCard">
          <CardHeader title={newsItem.title} />
          <SanitizeHTML html={newsItem.content} />
          <ReturnButton returnTo={-1} />
        </SubordinateCard>
      </MainCard>
    );
  }
};
export default NewsItem;
