import {
  UserInfoContextProvider,
  ErrorContextProvider,
  GameContextProvider,
  LoadingContextProvider,
  DialogContextProvider,
} from "./";

const ContextWrapper = ({ children }) => {
  return (
    <ErrorContextProvider>
      <LoadingContextProvider>
        <UserInfoContextProvider>
          <DialogContextProvider>
            <GameContextProvider>{children}</GameContextProvider>
          </DialogContextProvider>
        </UserInfoContextProvider>
      </LoadingContextProvider>
    </ErrorContextProvider>
  );
};
export default ContextWrapper;
