import React, {Fragment} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDialog, useUserInfo } from "../../contextFactory";
import { BasicButton, SvgButton } from "../../sharedComponents/Buttons";
import { MobileMenuIcon } from "../Icons";
import Drawer from "../DialogComponents/Drawer";
import UserGameData from "../UserGameData";
import styles from "./Navigation.module.scss";
import { getMetaplayApp } from '../../MetaplayApp.ts'
import Picture from "../Picture";

const MobileNavigation = ({
  handleLoginClick,
  handleLogoutClick,
  isLoggedIn,
}) => {
  const { t } = useTranslation();
  const { closeDialog, isOpen, setDialogContent, setDialogType, setIsOpen } =
    useDialog();

  const toggleNav = () => {
    if (isOpen) {
      closeDialog();
    } else {
      setIsOpen(true);
      setDialogType("drawer");
      setDialogContent("mobileNav");
    }
  };

  return (
    <Fragment>
    <Picture
      picStyle="logoImageMobile"
      source="images/fadLogo.png"
    />
    <nav className={styles.mobileNavigation}>
      <SvgButton
        svgElem={MobileMenuIcon}
        onClick={toggleNav}
        buttonStyle="navLink"
      />
      <Drawer>
        <ul id="linkList" className={styles.mobileNavList}>
          <li className={styles.navItem} onClick={toggleNav}>
            <Link className={styles.mobileNavLink} to="/">
              {t("Pages.FrontPage.PageName")}
            </Link>
          </li>
          <li className={styles.navItem} onClick={toggleNav}>
            <Link className={styles.mobileNavLink} to="/gems">
              {t("Pages.Gems.PageName")}
            </Link>
          </li>
          <li className={styles.navItem} onClick={toggleNav}>
            <Link className={styles.mobileNavLink} to="/community">
              {t("Pages.Community.PageName")}
            </Link>
          </li>
          <li className={styles.navItem} onClick={toggleNav}>
            <Link className={styles.mobileNavLink} to="/news">
              {t("Pages.NewsList.PageName")}
            </Link>
          </li>
          {isLoggedIn ? (
            <li onClick={toggleNav}>
              <BasicButton
                buttonStyle={["logoutButton", "mobileNavLink"]}
                onClick={handleLogoutClick}
              />
            </li>
          ) : (
            <li>
              <BasicButton
                buttonStyle="login"
                label={t("Common.Login")}
                onClick={handleLoginClick}
              />
            </li>
          )}
        </ul>
        <BasicButton
          type="button"
          onClick={toggleNav}
          buttonStyle="textLink"
          label={t("Common.Close")}
        />
      </Drawer>
    </nav>
    </Fragment>
  );
};

const DesktopNavigation = ({
  handleLoginClick,
  handleLogoutClick,
  isLoggedIn,
  showGameData,
}) => {
  const { t } = useTranslation();

  return (
    <nav className={styles.navigation}>
      <ul className={styles.navList}>
        <li>
          <Link className={styles.navLink} to="/">
            {t("Pages.FrontPage.PageName")}
          </Link>
        </li>
        <li>
          <Link className={styles.navLink} to="/gems">
            {t("Pages.Gems.PageName")}
          </Link>
        </li>
        <li>
          <Link className={styles.navLink} to="/community">
            {t("Pages.Community.PageName")}
          </Link>
        </li>
        <li>
          <Link className={styles.navLink} to="/news">
            {t("Pages.NewsList.PageName")}
          </Link>
        </li>
      </ul>
      <ul className={styles.navLogin}>
        {showGameData && <UserGameData />}
        {isLoggedIn ? (
          <li>
            <BasicButton
              buttonStyle={["navLink", "logoutButton"]}
              onClick={handleLogoutClick}
            />
          </li>
        ) : (
          <li>
            <BasicButton
              buttonStyle={["login"]}
              label={t("Common.Login")}
              onClick={handleLoginClick}
            />
          </li>
        )}
      </ul>
    </nav>
  );
};

const Navigation = () => {
  const { authStatus, userInfo } = useUserInfo();
  const isLoggedIn = authStatus?.isLoggedIn;
  const hasGameData = isLoggedIn && userInfo?.playerId;

  const handleLoginClick = () => {
    getMetaplayApp().loginWithPopup();
  };

  const handleLogoutClick = () => {
    getMetaplayApp().logoutWithRedirect();
  };

  return (
    <React.Fragment>
      <MobileNavigation
        handleLoginClick={handleLoginClick}
        handleLogoutClick={handleLogoutClick}
        isLoggedIn={isLoggedIn}
      />
      <DesktopNavigation
        handleLoginClick={handleLoginClick}
        handleLogoutClick={handleLogoutClick}
        isLoggedIn={isLoggedIn}
        showGameData={hasGameData}
      />
    </React.Fragment>
  );
};

export default Navigation;
