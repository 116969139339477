// Example implementation of how to load a Unity WebGL client.

import {getMetaplayApp} from "./MetaplayApp.ts";
import { singularSdk } from "singular-sdk";

let unityInstance;
let scriptElement;

let content = document.getElementById('unity-container');
function onMouse() {
  if (content != null) {
    content.style['pointer-events'] = 'auto';
  }

}
function onScroll() {
  if (content != null) {
    content.style['pointer-events'] = 'none';
  }
}

// Helper for injecting Unity's loader.js into the page for execution
async function injectUnityLoader(src) {
  return new Promise((resolve, reject) => {
    scriptElement = document.createElement('script')
    scriptElement.onload = resolve
    scriptElement.onerror = reject
    scriptElement.async = true
    scriptElement.src = src
    document.head.appendChild(scriptElement)
  })
}

export async function initializeUnityContainer(pathPrefix, props) {
  if (unityInstance) {
    unityInstance.SendMessage("ServiceManager", "RestartWebGLBuild");
    return;
  }

  // Hack mouse scroll away from game view
  document.addEventListener('wheel', onScroll, false);
  document.addEventListener('mousemove', onMouse, false);

  setTimeout( () => {
    sendSingularSessionEvent();
  }, 7000);
  
  const hideFullScreenButton = "false";
  const config = {
    dataUrl: pathPrefix + props.dataUrl,
    frameworkUrl: pathPrefix + props.frameworkUrl,
    codeUrl: pathPrefix + props.codeUrl,
    streamingAssetsUrl: pathPrefix + 'StreamingAssets',
    companyName: props.companyName,
    productName: props.productName,
    productVersion: props.productVersion
  }
  
  const container = document.querySelector("#unity-container");
  const canvas = document.querySelector("#unity-canvas");
  const loadingCover = document.querySelector("#loading-cover");
  const progressBarEmpty = document.querySelector("#unity-progress-bar-empty");
  const progressBarFull = document.querySelector("#unity-progress-bar-full");
  const fullscreenButton = document.querySelector("#unity-fullscreen-button");
  const spinner = document.querySelector('.spinner');
  content = document.getElementById('unity-container');
  
  const canFullscreen = (function () {
    for (const key of [
      'exitFullscreen',
      'webkitExitFullscreen',
      'webkitCancelFullScreen',
      'mozCancelFullScreen',
      'msExitFullscreen',
    ]) {
      if (key in document) {
        return true;
      }
    }
    return false;
  }());

  if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
    container.className = "unity-mobile";
    config.devicePixelRatio = 1;
  }
  loadingCover.style.display = "";
  
  await injectUnityLoader(pathPrefix + props.loaderUrl)
  
  // eslint-disable-next-line no-undef
  unityInstance = await createUnityInstance(canvas, config, (progress) => {
      spinner.style.display = "none";
      progressBarEmpty.style.display = "";
      progressBarFull.style.width = `${100 * progress}%`;
    });

  loadingCover.style.display = "none";
  if (canFullscreen) {
    if (!hideFullScreenButton) {
      fullscreenButton.style.display = "";
    }
    fullscreenButton.onclick = () => {
      unityInstance.SetFullscreen(1);
    };
  }
  await getMetaplayApp().initApiBridge(unityInstance);
  return unityInstance
}

function sendSingularSessionEvent() {
  try {
    singularSdk.event('startGame');
  }
  catch (error) {
    console.log(error);
  }
}

export function stopUnityInstance() {
  if (unityInstance) {
    unityInstance.SendMessage("ServiceManager", "StopWebGLBuild");
  }
}

export function notifyPurchasePopupClosed() {
  if (unityInstance) {
    unityInstance.SendMessage("ServiceManager", "OnPurchaseWindowClosed");
  }
}

export function notifyAccountChanged(accountId) {
  if (unityInstance) {
    unityInstance.SendMessage("ServiceManager", "OnAccountChanged", accountId);
  }
}
