const authApiBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;
const gameServerBaseUrl = process.env.REACT_APP_GAME_SERVER_BASE_URL;

export const authStatusConfig = {
  url: `${authApiBaseUrl}/selfServe/userInfo`,
  options: { credentials: "include" },
};

export const getUserGameDataConfig = (bearer) => {
  return {
    url: `${gameServerBaseUrl}/playerInfoForWebTBD?${new URLSearchParams({
      project: "behemoth",
      env: process.env.REACT_APP_ENVIRONMENT_NAME,
    })}`,
    options: {
      method: "GET",
      cache: "default",
      headers: { Authorization: "Bearer " + bearer },
    },
  };
};